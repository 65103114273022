import { useRecoilState } from "recoil";
import { NotificationType } from "../enums/notification-type";
import { notificationAtom } from "../atoms/notificationAtom";
import { UserNotification } from "../entities/user-notification";

export const useNotifications = function () {
  const [_, setNotificationState] = useRecoilState(notificationAtom);

  const setNotification = (
    title: string,
    type: NotificationType,
    description?: string,
  ) => {
    const notification = new UserNotification(title, description || '', type);

    setNotificationState(notification);

    setTimeout(() => {
      setNotificationState(undefined);
    }, 3000);
  };

  return { setNotification };
};
