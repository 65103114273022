import { axiosApi } from "./axiosConfig";
import { defineCancelApiObject } from "./axiosUtils";
import { RegisterResponseDto } from "./dtos/registerResponseDto";
import { Company } from "./entities/company";
import { PaginatedList } from "./entities/paginatedList";
import { Post } from "./entities/post";
import { SourceGroup } from "./entities/source-group";

export const api = {
  getPosts: async function (
    companyIds: number[],
    postIds: number[],
    sourceIds: number[],
    page = 1,
    cancel = false,
  ): Promise<PaginatedList<Post>> {
    const params = new URLSearchParams({
      pageSize: "25",
      pageNumber: page.toString(),
    });

    companyIds.forEach((id) => params.append("companyIds", id.toString()));
    postIds.forEach((id) => params.append("postIds", id.toString()));
    sourceIds.forEach((id) => params.append("sourceIds", id.toString()));

    const response = await axiosApi.request({
      url: `public/posts?${params.toString()}`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.getPosts.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  getCompany: async function (
    ticker: string,
    cancel = false,
  ): Promise<Company> {
    const response = await axiosApi.request({
      url: `public/company?ticker=${ticker}`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.getCompany.name].handleRequestCancellation()
            .signal
        : undefined,
    });
    return response.data;
  },
  getCompanies: async function (
    companyIds: number[],
    cancel = false,
  ): Promise<Company[]> {
    const params = new URLSearchParams();

    companyIds.forEach((id) => params.append("companyIds", id.toString()));

    const response = await axiosApi.request({
      url: `public/companies?${params.toString()}`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.getCompanies.name].handleRequestCancellation()
            .signal
        : undefined,
    });

    return response.data;
  },
  searchCompanies: async function (
    query: string,
    cancel = true,
  ): Promise<Company[]> {
    const response = await axiosApi.request({
      url: `public/search-companies?name=${query}`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.searchCompanies.name].handleRequestCancellation()
            .signal
        : undefined,
    });

    return response.data;
  },
  getSource: async function (
    sourceGroupSimplifiedName: string,
    cancel = false,
  ): Promise<SourceGroup> {
    const response = await axiosApi.request({
      url: `public/source-group?simplifiedName=${sourceGroupSimplifiedName}`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.getCompanies.name].handleRequestCancellation()
            .signal
        : undefined,
    });

    return response.data;
  },
  login: async function (
    login: string,
    password: string,
    cancel = false,
  ): Promise<{ status: number; expiresIn: number; accessToken: string }> {
    const response = await axiosApi.request({
      url: `/identity/login`,
      method: "POST",
      data: {
        email: login,
        password,
      },
      signal: cancel
        ? cancelApiObject[this.getCompanies.name].handleRequestCancellation()
            .signal
        : undefined,
    });

    return response.data;
  },
  register: async function (
    login: string,
    password: string,
    cancel = false,
  ): Promise<RegisterResponseDto> {
    const response = await axiosApi.request({
      url: `/identity/register`,
      method: "POST",
      data: {
        email: login,
        password,
      },
      signal: cancel
        ? cancelApiObject[this.getCompanies.name].handleRequestCancellation()
            .signal
        : undefined,
    });

    return response.data;
  },
};

export const cancelApiObject = defineCancelApiObject(api);
