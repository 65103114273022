import { Link } from "react-router-dom";
import { CompanySearch } from "./CompanySearch";
import { CompanySearchIcon } from "./CompanySearchIcon";
import { useRecoilState } from "recoil";
import { openMobileMenuAtom } from "../../../atoms/openMobileMenuAtom";
import { MobileMenu } from "./MobileMenu";
import { useState } from "react";
import { useLocalStorage } from "../../../hooks/useLocalStorage";

export const Header = () => {
  const [_, setIsOpen] = useRecoilState(openMobileMenuAtom);
  const [userName] = useLocalStorage("username", "");
  const [loggedIn] = useState(!!userName);
  return (
    <>
      <nav
        aria-label="Global"
        className="mx-auto max-w-5xl border-b border-b-gray-100 px-4 sm:px-6 lg:px-8"
      >
        <div className="relative flex items-center py-[1.5rem]">
          <Link className="flex-none text-slate-900" to="/">
            <span className="sr-only">Munger</span>
            <img
              src="/logo.png"
              className="h-11"
              alt="Skaner wiadomości giełdowych"
            />
          </Link>
          <div className="ml-auto hidden lg:flex lg:items-center">
            <Link className="ml-8 text-sm font-semibold" to="/">
              Skaner wiadomości
            </Link>
            <Link className="ml-8 text-sm font-semibold" to="/bookmarks">
              Zakładki
            </Link>
          </div>
          <CompanySearchIcon />
          <button
            onClick={() => setIsOpen(true)}
            type="button"
            className="-my-1 -mr-1 ml-6 flex h-8 w-8 items-center justify-center lg:hidden"
          >
            <span className="sr-only">Otwórz menu</span>
            <svg viewBox="0 0 24 24" className="h-6 w-6 stroke-slate-900">
              <path
                d="M3.75 12h16.5M3.75 6.75h16.5M3.75 17.25h16.5"
                fill="none"
                strokeWidth="1.5"
                strokeLinecap="round"
              ></path>
            </svg>
          </button>
          <div className="hidden lg:ml-8 lg:flex lg:items-center lg:border-l lg:border-slate-900/15 lg:pl-8">
            <div className="relative" data-headlessui-state="">
              {loggedIn ? (
                <button
                  className="flex items-center font-semibold hover:text-gray-900"
                  id="headlessui-menu-button-:R7q:"
                  type="button"
                  aria-haspopup="menu"
                  aria-expanded="false"
                  data-headlessui-state=""
                >
                  <span className="hidden items-center text-sm sm:flex">
                    Konto
                    <svg
                      aria-hidden="true"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-3 h-3 w-3 stroke-slate-400"
                    >
                      <path
                        d="M9.75 4.125 6 7.875l-3.75-3.75"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </span>
                  <span className="-my-1 flex h-8 w-8 items-center justify-center rounded-lg sm:hidden">
                    <svg
                      aria-hidden="true"
                      width="20"
                      height="20"
                      fill="none"
                      className="text-slate-900"
                    >
                      <path
                        d="M3.75 4.75h12.5M3.75 9.75h12.5M3.75 14.75h12.5"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </span>
                </button>
              ) : (
                <Link to="/login" className="text-sm">
                  Logowanie
                </Link>
              )}
            </div>
          </div>
        </div>
      </nav>
      {/* <div className="sticky top-0 z-40 overflow-x-auto whitespace-nowrap border-b border-slate-900/5 bg-transparent transition duration-100">
        <div className="mx-auto flex max-w-5xl justify-between space-x-8 py-4 text-sm font-semibold leading-6 text-slate-900">
          <div className="flex space-x-8 pl-4 sm:pl-6 lg:pl-8">
            <a href="#product-marketing">Marketing</a>
            <a href="#product-application-ui">Application UI</a>
            <a href="#product-ecommerce">Ecommerce</a>
          </div>
          <div className="flex space-x-8 pr-4 sm:pr-6 lg:pr-8"></div>
        </div>
      </div> */}
      <CompanySearch />
      <MobileMenu />
    </>
  );
};
