import { NotificationType } from "../enums/notification-type";

export class UserNotification {
  title: string;
  description: string;
  type: NotificationType;

  constructor(title: string, description: string, type: NotificationType) {
    this.title = title; 
    this.description = description;
    this.type = type;
  }
}
