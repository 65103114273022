import { CompanyScanner } from "./features/CompanyScanner/CompanyScanner";
import { Layout } from "./features/Layout/Layout";
import { NoMatch } from "./components/NoMatch";
import { PortfolioScanner } from "./features/PortfolioScanner/PortfolioScanner";
import { Routes, Route } from "react-router-dom";
import { Login } from "./features/UserAccount/Login";
import { Articles } from "./features/Articles/Articles";
import { Notification } from "./components/Notification";
import { Article } from "./features/Articles/Article";
import { Register } from "./features/UserAccount/Register";
import { Bookmarks } from "./features/Bookmarks/Bookmarks";
import { SourceScanner } from "./features/SourceScanner/SourceScanner";
import { AccountManagement } from "./features/UserAccount/AccountManagement";

export const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<PortfolioScanner />} />
          <Route path="bookmarks" element={<Bookmarks />} />
          <Route path="articles" element={<Articles />} />
          <Route path="article" element={<Article />} />
          <Route path="c/:ticker" element={<CompanyScanner />} />
          <Route path="s/:name" element={<SourceScanner />} />
          <Route path="account" element={<AccountManagement />} />
        </Route>
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
      <Notification />
    </>
  );
};
