import { Helmet } from "react-helmet-async";

export const AccountManagement = () => {
  return (
    <>
      <Helmet>
        <title>Zarządzanie kontem – Skaner wiadomości</title>
        <meta
          name="description"
          content={`Wiadomoście giełdowe na temat spółek z GPW i NewConnect z kilkudziesięciu różnych źródeł.`}
        />
      </Helmet>
      <div className="w-full border-b border-gray-100 py-4">
        <div className="mx-auto flex max-w-5xl items-center px-4 sm:px-6 lg:px-8">
          <div>
            <h1 className="mt-1 text-2xl font-bold leading-tight tracking-tight text-gray-900">
              Zarządzanie kontem
            </h1>
          </div>
          <div className="ml-auto min-w-28 text-right"></div>
        </div>
      </div>
      <div className="mx-auto w-full max-w-5xl px-4 py-[1.125rem] sm:px-6 lg:px-8">
        Account management
      </div>
    </>
  );
};
